import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

import { Button as MaterialButton } from '@mui/material';
import { COLORS } from '@constants/colors';

// PrimaryButton

interface IChildrenContainer {
    $loading?: boolean;
}

export const PrimaryButton = styled.button`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${COLORS.pink};
    opacity: ${(props) => (props.disabled ? '0.2' : '1')};
    box-shadow: 0 2px 8px ${COLORS.opacify(COLORS.pink, 0.2)};
    border-radius: 50px;
    color: ${COLORS.white};
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    padding: 16px 24px;
    transition: all 0.12s ease-out;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border: none;
    position: relative;
    box-shadow: 0 2px 8px ${COLORS.opacify(COLORS.pink, 0.32)};

    &:hover {
        transform: scale(${(props) => (props.disabled ? 1 : 1.03)});
        box-shadow: ${(props) => (props.disabled ? 'none' : '0 2px 4px #00000014')};
    }
    &:active {
        transform: scale(${(props) => (props.disabled ? 1 : 0.97)});
        box-shadow: ${(props) => (props.disabled ? 'none' : 'inset 0 1px 4px #00000008')};
    }
`;

export const ChildrenContainer = styled.div<IChildrenContainer>`
    display: flex;
    flex-direction: row;
    align-items: center;
    visibility: ${(props) => (props.$loading ? 'hidden' : 'visible')};
    color: ${COLORS.white};
`;

// OutlineButton

export const OutlineButton = muiStyled(MaterialButton)`
    color: ${COLORS.pink} ;
    text-align: center ;
    border: 2px solid ${COLORS.opacify(COLORS.pink, 0.2)} ;
    box-sizing: border-box ;
    border-radius: 16px ;

    &:hover {
        background: ${COLORS.pink} ;
        color: #ffffff ;

        svg {
            filter: brightness(0) invert(1);
        }
    }

    > span {
        color: inherit;
    }
`;

// NavigationButton

export const NavigationButton = muiStyled(MaterialButton)`
    border: solid 1px ${COLORS.opacify(COLORS.grey, 0.2)};
    padding: 8px;
    border-radius: 25px;
    color: ${COLORS.blueDarker};
    text-transform: none;
`;

export const Label = styled.span`
    margin: 0 10px;
    font-size: 0.975rem;
`;

// EmptyButton

export const EmptyButton = styled.button`
    cursor: pointer;
    color: ${COLORS.pink};
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    border: none;
    background: none;
`;
