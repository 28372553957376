import styled from 'styled-components';

import { TColorList, getColor } from '@santeacademie/synapse-composition-react';

import { TBadgeSize, TBadgeType } from './Badge';

interface IBadgeProps {
    size: TBadgeSize;
    badgeColor: TColorList;
    type: TBadgeType;
    /**
     * @deprecated Will be removed with the design system
     */
    badgeBackgroundColor?: string;
    /**
     * @deprecated Will be removed with the design system
     */
    badgeFontColor?: string;
}

const getBadgeBackgroundColor = (badgeColor: TColorList, type: TBadgeType, badgeBackgroundColor?: string): string => {
    if (badgeBackgroundColor != null && badgeBackgroundColor !== '') {
        return badgeBackgroundColor;
    }
    const colorStep = type === 'light' ? '050' : '700';
    return getColor(badgeColor, colorStep);
};

const getBadgeFontColor = (badgeColor: TColorList, type: TBadgeType, badgeFontColor?: string): string => {
    if (badgeFontColor != null && badgeFontColor !== '') {
        return badgeFontColor;
    }
    const colorStep = type === 'light' ? '700' : '050';
    return getColor(badgeColor, colorStep);
};

const containerPadding = {
    small: '4px 8px',
    medium: '8px 12px',
    large: '8px 16px',
};

const fontSize = {
    small: '13',
    medium: '16',
    large: '20',
};

export const Container = styled.div<IBadgeProps>`
    display: inline-block;
    border-radius: 5px;
    padding: ${({ size }) => containerPadding[size]};
    background-color: ${({ badgeColor, type, badgeBackgroundColor }) =>
        getBadgeBackgroundColor(badgeColor, type, badgeBackgroundColor)};
`;

export const Content = styled.div`
    color: ${getColor('white')};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

export const Title = styled.div<IBadgeProps>`
    color: ${getColor('white')};
    font-size: ${({ size }) => fontSize[size]}px;
    color: ${({ badgeColor, type, badgeFontColor }) => getBadgeFontColor(badgeColor, type, badgeFontColor)};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
