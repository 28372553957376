import React, { useState, useContext } from 'react';

import { Avatar } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useQueryClient } from '@tanstack/react-query';

import { ContentStudioApiContext } from '@santeacademie/contentstudio-api-client';

import { t } from '@locales/translation';
import { toAnimalAvatar } from '@utils/avatar';
import { CookieStoreContext } from '@stores/CookieStoreContext';

import * as S from './styled';

const UserMenu = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { logout } = useContext(ContentStudioApiContext);
    const { learnerIdentifierCookie, removeLearnerIdentifierCookie } = useContext(CookieStoreContext);
    const queryClient = useQueryClient();

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Remove any
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = async (event: any) => {
        event.preventDefault();

        try {
            await logout({});
            removeLearnerIdentifierCookie();
            queryClient.invalidateQueries();
        } catch (error: any) {
        } finally {
        }

        // Avoid using router on this one, it may generates redirection loop in some circumstances
        document.location.href = '/login';
    };

    return (
        <S.UserMenuContainer>
            <S.AvatarButton onClick={handleClick}>
                <Avatar alt="" src={toAnimalAvatar(learnerIdentifierCookie.id + '')} />
            </S.AvatarButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleLogout}>{t('header.logout')}</MenuItem>
            </Menu>
        </S.UserMenuContainer>
    );
};

export default UserMenu;
