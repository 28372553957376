import styled from 'styled-components';

import { SCREENS } from '@constants/screens';
import { COLORS } from '@constants/colors';

interface ITitle {
    titleLength: number;
}

export const Container = styled.div`
    min-width: 384px;
    height: 240px;
    background-color: #eee;
    border-radius: 14px;
    padding: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    margin-right: 16px;
    cursor: pointer;
    box-sizing: content-box;
    background-size: 105%;
    transition: background-size 0.1s ease-out, -webkit-background-size 0.1s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${SCREENS.tabletAndLower} {
        min-width: 208px;
        height: 128px;
    }

    &:hover {
        background-size: 120%;
    }

    &::after {
        position: absolute;
        border-radius: 15px;
        content: '';
        height: 50%;
        width: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            transparent 0%,
            ${COLORS.opacify(COLORS.blueDarker, 0.2)} 33.79%,
            ${COLORS.opacify(COLORS.blueDarker, 0.8)} 87.68%
        );
    }
`;

export const Title = styled.div<ITitle>`
    color: #ffffff;
    font-weight: 800;
    z-index: 1;

    text-shadow: 0px 0px 5px #000;

    font-size: ${(props: ITitle) => {
        if (props.titleLength > 25) {
            return 22;
        }

        return 24;
    }}px;

    ${SCREENS.tabletAndLower} {
        width: 12ch;
        line-height: 1;
        word-break: break-word;
        padding: 3px;

        font-size: ${(props: ITitle) => {
        if (props.titleLength > 25) {
            return 14;
        }

        return 18;
    }}px;
    }
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const Footer = styled.div`
    z-index: 1;
    margin-top: auto;
`;

export const BadgeContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`