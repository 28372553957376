const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    let tQuad = t;
    tQuad /= d / 2;
    if (tQuad < 1) return (c / 2) * tQuad * tQuad + b;
    tQuad -= 1;
    return (-c / 2) * (tQuad * (tQuad - 2) - 1) + b;
};

export const scrollHorizontal = (
    type: 'left' | 'right',
    element: HTMLElement,
    change: number,
    duration: number,
): void => {
    const start = element.scrollLeft;
    let currentTime = 0;
    const increment = 20;
    const scrollElement = element;

    const animateScroll = () => {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, type === 'left' ? change : -change, duration);
        scrollElement.scrollLeft = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
};

export const scrollDivBottom = (div: HTMLDivElement | undefined, duration: number | undefined = 500): void => {
    if (div !== undefined) {
        const targetScrollTop = div.scrollHeight;
        const startTime = performance.now();

        const animateScroll = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);

            div.scrollTop = progress * targetScrollTop;

            if (progress < 1) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    }
};
