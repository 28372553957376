import React from 'react';

import Svg, {ISvgIcon} from './Svg';

interface IChevronContainer {
	path: string
}

const ChevronContainer = ({scale, fill, className, horizontalOffset, verticalOffset, path}: ISvgIcon & IChevronContainer): JSX.Element => (
	<Svg
		width={24}
		height={24}
		viewBox={`${-1*(horizontalOffset||0)} ${-1*(verticalOffset||0)} 24 24`}
		scale={scale}
		fill={fill}
		className={className}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d={path}
		/>
	</Svg>
);

const ChevronLeft = ({scale, fill, className, horizontalOffset, verticalOffset}: ISvgIcon): JSX.Element => (
	<ChevronContainer scale={scale} fill={fill} className={className} horizontalOffset={horizontalOffset} verticalOffset={verticalOffset} path='M15.7923 5.27542C16.0893 5.61892 16.0648 6.15016 15.7377 6.46197L9.97339 11.9562L15.5461 17.553C15.8654 17.8736 15.8767 18.4054 15.5713 18.7406C15.2659 19.0759 14.7595 19.0877 14.4402 18.767L8.24703 12.5471C8.08662 12.386 7.99717 12.1622 8.00007 11.9291C8.00296 11.6961 8.09794 11.4748 8.2623 11.3181L14.6623 5.21804C14.9894 4.90623 15.4954 4.93192 15.7923 5.27542Z' />
);

const ChevronRight = ({scale, fill, className, horizontalOffset, verticalOffset}: ISvgIcon): JSX.Element => (
	<ChevronContainer scale={scale} fill={fill} className={className} horizontalOffset={horizontalOffset} verticalOffset={verticalOffset} path='M8.20766 18.7246C7.91069 18.3811 7.93516 17.8498 8.2623 17.538L14.0266 12.0438L8.45386 6.44703C8.13457 6.12636 8.12331 5.59464 8.42871 5.25938C8.7341 4.92413 9.24051 4.91231 9.5598 5.23297L15.753 11.4529C15.9134 11.614 16.0028 11.8378 15.9999 12.0709C15.997 12.3039 15.9021 12.5252 15.7377 12.6819L9.33771 18.782C9.01056 19.0938 8.50462 19.0681 8.20766 18.7246Z' />
);

const ChevronUp = ({scale, fill, className, horizontalOffset, verticalOffset}: ISvgIcon): JSX.Element => (
	<ChevronContainer scale={scale} fill={fill} className={className} horizontalOffset={horizontalOffset} verticalOffset={verticalOffset} path='M18.7246 15.7923C18.3811 16.0893 17.8498 16.0648 17.538 15.7377L12.0438 9.97339L6.44703 15.5461C6.12636 15.8654 5.59464 15.8767 5.25938 15.5713C4.92413 15.2659 4.91231 14.7595 5.23297 14.4402L11.4529 8.24703C11.614 8.08662 11.8378 7.99717 12.0709 8.00007C12.3039 8.00296 12.5252 8.09794 12.6819 8.2623L18.782 14.6623C19.0938 14.9894 19.0681 15.4954 18.7246 15.7923Z' />
);

const ChevronDown = ({scale, fill, className, horizontalOffset, verticalOffset}: ISvgIcon): JSX.Element => (
	<ChevronContainer scale={scale} fill={fill} className={className} horizontalOffset={horizontalOffset} verticalOffset={verticalOffset} path='M5.27542 8.20766C5.61892 7.91069 6.15016 7.93516 6.46197 8.2623L11.9562 14.0266L17.553 8.45386C17.8736 8.13457 18.4054 8.12331 18.7406 8.42871C19.0759 8.7341 19.0877 9.24051 18.767 9.5598L12.5471 15.753C12.386 15.9134 12.1622 16.0028 11.9291 15.9999C11.6961 15.997 11.4748 15.9021 11.3181 15.7377L5.21804 9.33771C4.90623 9.01056 4.93192 8.50462 5.27542 8.20766Z' />
);

export {ChevronLeft, ChevronRight, ChevronUp, ChevronDown};
