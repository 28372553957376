import React, { useMemo } from 'react';

import { IRegistrationShort } from '@santeacademie/contentstudio-api-client';
import { getRegistrationProgress } from '@santeacademie/business-logic';

import { getStatusBarStatusFromRegistrationStatus, getShouldShowProgress, StatusBar } from '../index';
import * as S from './styled';

type IDetailedCourseDatasProps = {
    registration: IRegistrationShort;
};

export const DetailedCourseDatas = ({ registration }: IDetailedCourseDatasProps): JSX.Element => {
    const registrationProgress = useMemo(() => getRegistrationProgress(registration), [registration]);
    const registrationRedundantProgression = useMemo(
        () => Math.floor(registration.redundantProgression * 100),
        [registration.redundantProgression],
    );

    const shouldShowProgress = getShouldShowProgress(registration.status, registrationProgress);

    return (
        <S.BottomContainer>
            <S.RegistrationInformations>
                <S.CourseTitle titleLength={registration.course.name.length}>{registration.course.name}</S.CourseTitle>
                {shouldShowProgress ? (
                    <StatusBar
                        status={getStatusBarStatusFromRegistrationStatus(registration.status)}
                        registrationId={registration.id}
                        progression={registrationRedundantProgression}
                    />
                ) : null}
            </S.RegistrationInformations>
        </S.BottomContainer>
    );
};
