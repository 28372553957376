import React from 'react';

import Svg, {ISvgIcon} from './Svg';

const SanteAcademieLogo = ({scale, fill, className}: ISvgIcon): JSX.Element => (
	<Svg
		width={122}
		height={41}
		viewBox='0 0 122 41'
		scale={scale}
		fill={fill}
		className={className}
	>
		<path d='M36.514 24.4489C36.099 24.4904 35.6009 24.5319 35.1027 24.5319C32.3217 24.5319 28.4613 23.5772 27.4651 20.298C26.6349 17.6414 28.3299 15.4 30.4538 15.1924C30.6594 15.1579 30.868 15.144 31.0764 15.1509C32.7367 15.1509 33.6914 16.0157 33.8575 16.4792C33.8665 16.4981 33.8715 16.5188 33.8722 16.5397C33.8728 16.5607 33.8692 16.5816 33.8614 16.6011C33.8536 16.6206 33.8418 16.6383 33.8269 16.653C33.8119 16.6677 33.7941 16.6792 33.7745 16.6867C33.4009 16.8597 31.9481 17.849 32.3217 20.3395C32.6537 22.498 34.2241 23.8263 35.9329 23.8263C36.0575 23.8263 36.2235 23.7848 36.348 23.7848C38.9216 23.3697 39.8348 20.464 39.1706 18.4232C38.6725 17.0119 37.4688 16.0572 35.6009 15.061C34.065 14.2308 31.6229 12.9855 31.5745 12.944C30.5368 12.3214 28.5028 11.0415 28.5859 8.21201C28.7104 4.76678 32.3217 3.31396 35.8084 3.31396C38.2989 3.31396 40.7895 4.22716 41.9102 5.51393C41.9508 5.57553 41.9725 5.64769 41.9725 5.72148C41.9725 5.79526 41.9508 5.86742 41.9102 5.92902C41.4536 6.51015 40.1253 8.83464 39.5857 9.49878C39.5857 9.54029 39.5442 9.54029 39.5027 9.54029C39.4703 9.53825 39.4398 9.52448 39.4169 9.50155C39.394 9.47863 39.3802 9.44812 39.3782 9.41577C39.2951 7.38183 39.0046 3.89509 35.4348 3.85358C34.1896 3.85358 32.4946 4.4347 32.4462 5.92902C32.4047 6.92524 32.9443 7.50636 34.5216 8.37805C36.4241 9.33275 38.548 10.661 39.7517 11.4082C42.4498 13.1516 43.1555 14.8119 43.3215 16.0987C43.9441 21.1697 40.0423 23.8678 36.514 24.4489Z' />
		<path d='M53.7822 19.9239C52.2879 19.9239 51.5822 19.0107 51.4144 17.7655C50.8333 18.7202 49.422 20.1315 47.3881 20.256C45.3126 20.256 43.4102 19.5918 43.4102 17.1843C43.4102 12.5769 50.4667 11.3731 51.3384 11.2901C51.4214 10.5429 51.6722 7.67881 49.4722 7.67881H49.2214C47.561 7.85176 46.6894 9.96179 46.6478 11.5806C46.6478 11.6637 46.5648 11.7052 46.4749 11.6222C46.1013 11.0825 45.2296 9.96179 44.8976 9.5052C44.8571 9.45937 44.8347 9.40032 44.8347 9.33916C44.8347 9.27801 44.8571 9.21896 44.8976 9.17313C45.9768 7.55428 48.3013 6.84863 49.6711 6.84863C52.1633 6.84863 56.2312 7.6373 56.2312 12.9504V17.1843C56.2312 18.2636 56.5218 18.9277 57.0199 19.9239H53.7822ZM49.3805 18.5956C50.0879 18.5956 50.8766 17.9315 51.3747 17.2674C51.339 17.1462 51.3244 17.0199 51.3314 16.8938V11.6637C50.3834 11.9629 49.5494 12.5447 48.9411 13.331C48.3328 14.1174 47.9792 15.0708 47.9277 16.0636C47.8447 17.1843 48.1007 18.3466 49.09 18.5541C49.182 18.5912 49.2817 18.6054 49.3805 18.5956Z' />
		<path d='M62.8325 6.43408V9.04914C63.1267 8.40997 63.5934 7.86549 64.18 7.47687C64.7666 7.08825 65.4499 6.87088 66.1532 6.84917C69.889 6.84917 71.4663 9.54724 71.4663 13.034V17.1849C71.4663 18.2641 71.7154 18.9698 72.255 19.8829H69.0173C67.1494 19.8829 66.5683 18.6377 66.5683 16.8943V11.5328C66.5683 10.1214 66.4853 8.0045 64.6174 8.0045C63.2891 8.21204 62.791 10.2529 62.874 11.9478V17.0119C62.874 18.1327 63.1231 18.8798 63.6627 19.876H57.0195C57.5591 18.8798 57.8082 18.1327 57.8082 17.0119V10.751C57.8082 9.63026 57.5591 8.8831 57.0195 7.88689L62.8325 6.43408Z' />
		<path d='M71.6718 7.18121H71.7549C75.2001 7.18121 78.1887 2.20015 78.1887 2.20015C78.3132 1.9926 78.3617 2.11713 78.3617 2.11713V7.18121H81.3019C81.335 7.17973 81.368 7.18517 81.3989 7.19716C81.4298 7.20916 81.4578 7.22745 81.4813 7.25088C81.5047 7.27431 81.523 7.30235 81.535 7.33324C81.547 7.36412 81.5524 7.39716 81.5509 7.43026V7.60321C81.5524 7.63631 81.547 7.66936 81.535 7.70024C81.523 7.73113 81.5047 7.75917 81.4813 7.7826C81.4578 7.80602 81.4298 7.82432 81.3989 7.83631C81.368 7.84831 81.335 7.85374 81.3019 7.85227H78.3617V14.2516C78.4032 16.4515 79.0258 17.5377 80.2642 17.5723C80.7208 17.5723 81.4749 17.1987 81.8415 16.576C81.883 16.5345 81.9245 16.493 81.966 16.493C82.0119 16.493 82.0559 16.5113 82.0883 16.5437C82.1208 16.5761 82.139 16.6201 82.139 16.666C82.0975 18.3263 80.9283 20.3188 78.5277 20.4848C74.9995 20.4848 73.2976 18.4924 73.2976 14.9641L73.3391 7.87302H71.6788C71.6142 7.86858 71.5534 7.84092 71.5076 7.79514C71.4618 7.74936 71.4341 7.68856 71.4297 7.62397V7.45101C71.429 7.38389 71.4534 7.31893 71.4982 7.26898C71.5431 7.21902 71.605 7.1877 71.6718 7.18121Z' />
		<path d='M94.5028 15.5243C94.5197 15.522 94.5369 15.5237 94.553 15.5291C94.5692 15.5346 94.5839 15.5437 94.5959 15.5557C94.608 15.5678 94.6171 15.5825 94.6225 15.5986C94.628 15.6148 94.6296 15.632 94.6273 15.6488C94.3368 18.1394 91.8462 20.1318 88.9821 20.2563C85.0388 20.2563 82.1332 17.9318 81.9256 13.857C81.7527 10.7439 83.503 7.0911 88.2765 6.84204C88.4494 6.84204 88.6501 6.80054 88.8161 6.80054C91.7563 6.80054 94.0047 8.66843 93.6726 10.7439C93.2575 13.5665 89.0652 14.5212 87.0727 14.6872C87.478 15.3564 88.0514 15.9078 88.736 16.2864C89.4205 16.6651 90.1923 16.8579 90.9746 16.8457C92.2198 16.8457 93.5066 16.4721 94.4198 15.5589C94.4424 15.5377 94.4719 15.5254 94.5028 15.5243ZM86.1595 5.18861C86.4501 5.10559 89.6463 4.52447 89.6048 0.207546C89.9383 0.0865873 90.2881 0.0166169 90.6425 0C92.1783 0 92.7594 1.49432 92.2613 2.65656C91.3066 4.81503 88.8576 5.85275 87.1142 5.85275C86.9196 5.86114 86.7246 5.84721 86.5331 5.81124C86.1595 5.76973 85.869 5.27163 86.1595 5.18861ZM86.8237 14.2375C87.8614 13.9055 89.2727 12.7017 89.5633 10.7923C89.7708 9.71307 89.2727 7.55461 88.0274 7.50619C86.7407 7.46468 86.1595 9.58163 86.1249 10.9099C86.0601 12.0613 86.3012 13.2094 86.8237 14.2375Z' />
		<path d='M0.440633 31.2715C1.6098 28.7862 3.80285 27.8557 5.9959 27.8557C7.53716 27.8793 9.04686 28.296 10.382 29.0664C10.8906 29.3402 11.379 29.65 11.8435 29.9934L15.2541 20.2959L17.7879 20.3443L22.8035 33.3556C23.7306 35.5487 24.8513 38.3713 26.3612 39.7878H17.5942C17.9833 39.3969 17.8865 38.3747 17.5942 37.2038C16.1897 35.0287 14.3472 33.1702 12.1842 31.7472C11.2589 34.7687 10.868 37.7902 13.2081 39.7878H6.4352C8.04194 38.3747 9.40655 36.6746 11.162 31.9426L11.4042 31.2508C10.2834 30.6178 9.26126 30.277 8.48124 30.277C7.21349 30.3255 6.14118 31.0069 5.80046 32.4199C5.26431 34.4677 6.4352 36.2249 7.06821 36.5708C7.16507 36.6193 7.16507 36.7178 7.11664 36.8147C6.77073 37.4477 5.50817 37.9839 4.14357 37.9839C3.49853 38.0075 2.85911 37.8563 2.29296 37.5463C-0.0955252 36.2907 -0.436242 33.0754 0.440633 31.2715ZM16.6187 34.0492C16.3731 33.3176 14.0365 26.645 14.0365 26.645L12.6235 30.5434C14.0537 31.5947 15.3904 32.7677 16.6187 34.0492Z' />
		<path d='M37.9768 36.2028C37.3126 39.0254 34.49 40.1461 31.875 40.1461C27.7241 39.8556 25.3166 36.7009 25.5241 33.0066C25.7732 29.8104 28.0977 26.7388 31.9995 26.7388C34.5315 26.7388 36.69 28.1501 37.6447 30.5161C37.6862 30.6406 37.6447 30.7236 37.5202 30.8066C36.8976 31.3463 35.6938 32.5085 34.7806 33.1311C34.7643 33.1469 34.7446 33.1588 34.723 33.166C34.7015 33.1732 34.6786 33.1755 34.6561 33.1726C34.5851 33.1656 34.5196 33.1315 34.4732 33.0774C34.4268 33.0232 34.4031 32.9533 34.407 32.8821C34.58 30.5991 33.6184 27.4859 32.2901 27.3614C31.0033 27.2784 30.1731 29.2293 30.0071 30.5576C29.509 34.1274 31.5014 37.033 34.7391 37.033C35.8183 37.033 36.9391 36.7424 37.7692 36.0783C37.8523 35.9952 37.9768 36.0783 37.9768 36.2028Z' />
		<path d='M48.3586 39.8141C46.8643 39.8141 46.1586 38.9009 45.9926 37.6556C45.4114 38.6103 44.0001 40.0216 41.9662 40.1461C39.8908 40.1461 37.9883 39.482 37.9883 37.0745C37.9883 32.467 45.0448 31.2632 45.9165 31.1802C45.9995 30.4331 46.2485 27.569 44.0486 27.569H43.7995C42.1392 27.7419 41.2675 29.8519 41.226 31.4708C41.226 31.5538 41.143 31.5953 41.053 31.5123C40.6794 30.9727 39.8078 29.8519 39.4757 29.3953C39.4352 29.3495 39.4128 29.2905 39.4128 29.2293C39.4128 29.1682 39.4352 29.1091 39.4757 29.0633C40.5549 27.4444 42.8794 26.7388 44.2492 26.7388C46.7397 26.7388 50.8076 27.5274 50.8076 32.8406V37.0745C50.8076 38.1537 51.0982 38.8179 51.5963 39.8141H48.3586ZM43.9586 38.4858C44.6643 38.4858 45.453 37.8216 45.9511 37.1575C45.9168 37.0361 45.9028 36.9099 45.9096 36.7839V31.5538C44.9615 31.8531 44.1275 32.4348 43.5192 33.2212C42.9109 34.0075 42.5573 34.9609 42.5058 35.9537C42.4228 37.0745 42.6788 38.2367 43.6681 38.4443C43.7602 38.4813 43.8599 38.4956 43.9586 38.4858Z' />
		<path d='M64.9594 37.1156C64.9594 38.1534 65.2085 38.859 65.7481 39.8137H62.5104C61.3897 39.8137 60.684 39.3156 60.3104 38.6031C59.8953 39.2672 59.3142 39.9728 58.2765 40.0974C58.0848 40.1318 57.89 40.1457 57.6954 40.1389C55.2048 40.1389 52.2577 38.3955 51.8426 33.4975C51.469 29.3881 53.9181 27.0636 56.4086 27.0636H56.7822C57.4998 27.1253 58.1829 27.3989 58.7446 27.8498C59.3064 28.3006 59.7214 28.9083 59.9368 29.5956L59.8953 26.5655C59.8953 25.4447 59.6463 24.6976 59.1067 23.7014L64.9594 22.041V37.1156ZM59.9438 35.6628V31.5535C59.7708 29.8101 59.2381 29.1044 58.657 29.1044C57.7023 29.0629 56.54 30.3151 56.457 32.9233C56.374 35.8635 57.2042 38.6515 58.2834 39.3571C58.3774 39.4341 58.4941 39.4778 58.6155 39.4816C59.2658 39.4816 59.9368 38.0288 59.9368 35.6628H59.9438Z' />
		<path d='M78.5302 35.414C78.5471 35.4117 78.5642 35.4133 78.5804 35.4188C78.5965 35.4242 78.6112 35.4333 78.6233 35.4454C78.6353 35.4574 78.6444 35.4721 78.6499 35.4883C78.6553 35.5044 78.657 35.5216 78.6547 35.5385C78.3641 38.029 75.8736 40.0214 73.0095 40.146C69.0661 40.146 66.1605 37.8215 65.953 33.7467C65.78 30.6335 67.5303 26.9807 72.3038 26.7317C72.4768 26.7317 72.6774 26.6902 72.8434 26.6902C75.7837 26.6902 78.0321 28.5581 77.7 30.6335C77.2849 33.4561 73.0925 34.4108 71.1001 34.5769C71.5046 35.2476 72.078 35.8005 72.7629 36.1804C73.4479 36.5604 74.2205 36.7541 75.0036 36.7422C76.2489 36.7422 77.5357 36.3687 78.4489 35.4555C78.4699 35.4321 78.4989 35.4173 78.5302 35.414ZM70.1869 25.0783C70.4774 24.9952 73.6736 24.4141 73.6321 20.0972C73.9656 19.9762 74.3155 19.9063 74.6698 19.8896C76.2057 19.8896 76.7868 21.384 76.2887 22.5462C75.334 24.7047 72.885 25.7424 71.1416 25.7424C70.9469 25.7508 70.7519 25.7369 70.5605 25.7009C70.1869 25.6594 69.8963 25.1613 70.1869 25.0783ZM70.8528 34.1272C71.8905 33.7951 73.3018 32.5914 73.5923 30.682C73.7999 29.6027 73.3018 27.4443 72.0565 27.3958C70.7697 27.3543 70.1886 29.4713 70.154 30.7996C70.0892 31.951 70.3303 33.0991 70.8528 34.1272Z' />
		<path d='M78.8633 39.7726C79.4029 38.7764 79.6519 38.0292 79.6519 36.9085V30.6406C79.6519 29.5199 79.4029 28.7727 78.8633 27.7765L84.6745 26.3237V29.1048C85.1311 27.9426 86.0443 26.9879 87.8292 26.7803C88.0022 26.7803 88.1613 26.7388 88.3688 26.7388C89.9462 26.7388 91.9386 27.403 92.6927 29.3124C93.4813 27.064 95.1002 26.7388 96.719 26.7388C99.4171 26.7388 101.659 28.0671 101.659 32.6746V37.116C101.659 38.1538 101.909 38.8594 102.449 39.7726H99.2026C97.3347 39.7726 96.7536 38.5273 96.7536 36.8324V31.4708C96.7536 31.0142 96.9265 27.8596 95.0517 27.7765C94.2216 27.735 93.0178 28.5652 93.0178 31.5538V37.116C93.0178 38.1538 93.2669 38.8594 93.8065 39.7726H90.5705C88.7026 39.7726 88.08 38.5273 88.08 36.8324V31.4708C88.08 31.0557 88.329 27.9011 86.5857 27.9011C86.5026 27.9011 86.4127 27.9426 86.3366 27.9426C85.2159 28.1501 84.6347 30.3501 84.7178 32.0104V36.9085C84.7178 38.0292 84.9668 38.7764 85.5064 39.7726H78.8633Z' />
		<path d='M109.075 39.7726H102.434C102.973 38.7764 103.222 38.0292 103.222 36.9085V31.2633C103.222 30.101 102.89 29.3124 102.434 28.3992C102.434 28.3992 102.849 28.4407 103.43 28.4407C104.758 28.4407 107.124 28.2331 108.245 26.9464C108.245 28.157 108.286 36.9085 108.286 36.9085C108.292 38.0292 108.541 38.7764 109.075 39.7726ZM103.022 24.1653C103.018 23.644 103.168 23.1332 103.454 22.6975C103.741 22.2618 104.15 21.9207 104.63 21.7174C105.11 21.5142 105.639 21.4578 106.151 21.5555C106.663 21.6531 107.135 21.9005 107.506 22.2662C107.878 22.6319 108.133 23.0996 108.238 23.61C108.344 24.1205 108.296 24.6509 108.1 25.134C107.905 25.6172 107.57 26.0315 107.139 26.3246C106.708 26.6176 106.199 26.7762 105.678 26.7803C104.982 26.7778 104.314 26.5027 103.817 26.014C103.321 25.5253 103.035 24.8618 103.022 24.1653Z' />
		<path d='M121.874 35.4137C121.891 35.4114 121.908 35.4131 121.924 35.4185C121.94 35.424 121.955 35.4331 121.967 35.4451C121.979 35.4572 121.988 35.4719 121.994 35.488C121.999 35.5042 122.001 35.5214 121.998 35.5382C121.708 38.0288 119.217 40.0212 116.353 40.1457C112.41 40.1457 109.504 37.8212 109.297 33.7464C109.124 30.6333 110.874 26.9805 115.648 26.7314C115.821 26.7314 116.021 26.6899 116.187 26.6899C119.127 26.6899 121.376 28.5578 121.044 30.6333C120.629 33.4559 116.436 34.4106 114.444 34.5766C114.849 35.2458 115.423 35.7972 116.107 36.1759C116.792 36.5545 117.563 36.7474 118.346 36.7351C119.591 36.7351 120.878 36.3615 121.791 35.4483C121.813 35.4271 121.843 35.4148 121.874 35.4137ZM114.195 34.1269C115.232 33.7949 116.644 32.5911 116.934 30.6817C117.142 29.6025 116.644 27.444 115.399 27.3956C114.112 27.3541 113.531 29.471 113.496 30.7993C113.429 31.95 113.667 33.098 114.186 34.1269H114.195Z' />
	</Svg>
);

export {SanteAcademieLogo};
