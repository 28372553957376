import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';

// DetailedCourseCard

interface ITitle {
    titleLength: number;
}

export const Container = styled.div`
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${SCREENS.tabletAndLower} {
        width: 100%;
        padding-bottom: 60%;
    }

    ${SCREENS.tabletAndUpper} {
        width: 464px;
        height: 280px;
    }

    ${SCREENS.desktopLargeAndUpper} {
        width: 1024px;
        height: 616px;
    }

    ${SCREENS.desktopMedium} {
        width: 704px;
        height: 424px;
    }

    &::after {
        position: absolute;
        border-radius: 15px;
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            transparent 0%,
            ${COLORS.opacify(COLORS.blueDarker, 0.2)} 33.79%,
            ${COLORS.opacify(COLORS.blueDarker, 0.8)} 87.68%
        );
    }
`;

export const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
`;

export const ResumeButtonContainer = styled.div`
    z-index: 999;
    a {
        text-decoration: none;
    }
    ${SCREENS.tabletAndLower} {
        position: absolute;
        bottom: 50%;
    }
`;

export const ButtonLabel = styled.span`
    margin-left: 10px;
    color: #ffffff;

    ${SCREENS.tabletAndLower} {
        margin: 0 0 0 10px;
    }
`;

// DetailedCourseDatas

const DetailedInner = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 5%;
    width: 90%;
    height: 96%;
`;

export const BottomContainer = styled(DetailedInner)`
    flex-direction: column;
    align-items: flex-end;
    z-index: 998;
`;

export const RegistrationInformations = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TitlePercentage = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RemainingDuration = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: end;
    max-width: 200px;
    flex-shrink: 0;
`;

export const CourseTitle = styled.span<ITitle>`
    color: #ffffff;
    font-weight: 800;

    font-size: ${(props: ITitle) => (props.titleLength > 25 ? '30' : '40')}px;

    ${SCREENS.desktopMediumAndLower} {
        font-size: ${(props: ITitle) => {
        if (props.titleLength > 17) {
            if (props.titleLength > 25) {
                return '15';
            } else {
                return '18';
            }
        } else {
            return '21';
        }
    }}px;
    }
`;

export const RemainingDurationTitle = styled.span`
    color: ${COLORS.greyLighter};
    opacity: 0.7;
    font-size: 13px;
    font-weight: 400;
`;

export const RemainingDurationValue = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 100%;
    margin-top: 5px;
    margin-bottom: 1px;
`;

export const DayValue = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: #ffffff;
`;

export const DateValue = styled.span`
    font-style: normal;
    font-size: 13px;
    color: #ffffff;
`;
