import styled from 'styled-components';
import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';

export const StyledAppBar = styled.div`
    background-color: #ffffff;
    box-shadow: none;
    border-bottom: solid 1px ${COLORS.opacify(COLORS.grey, 0.2)};
    height: 88px;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const StyledToolbar = styled.div`
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 88px;
    min-height: 64px;
    flex: 1;

    ${SCREENS.desktopMediumAndLower} {
        padding-left: 16px;
    }
`;
