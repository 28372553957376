import { HTMLAttributes } from 'react';

import { TColorList } from '@santeacademie/synapse-composition-react';

import * as S from './styled';

export type TBadgeSize = 'small' | 'medium' | 'large';
export type TBadgeType = 'light' | 'dark';

type TBadgeState = 'default';

type DivAttributes = Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

export interface IBadge extends DivAttributes {
    title: string;
    size: TBadgeSize;
    color: TColorList;
    type: TBadgeType;
    state: TBadgeState;
    /**
     * @deprecated Will be removed with the design system
     */
    backgroundColor?: string;
    /**
     * @deprecated Will be removed with the design system
     */
    fontColor?: string;
}

export const Badge = ({ title, size, color, type, backgroundColor, fontColor, ...rest }: IBadge): JSX.Element => (
    <S.Container badgeColor={color} size={size} type={type} badgeBackgroundColor={backgroundColor} {...rest}>
        <S.Title badgeColor={color} size={size} type={type} badgeFontColor={fontColor}>
            {title}
        </S.Title>
    </S.Container>
);
