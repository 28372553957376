import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 384px;
    height: 240px;
    border: 2px dashed ${COLORS.opacify(COLORS.blueDarker, 0.3)};
    box-sizing: border-box;
    border-radius: 14px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: ${COLORS.greyLighter};
    margin-right: 10px;
    cursor: pointer;

    ${SCREENS.tabletAndLower} {
        min-width: 208px;
        height: 128px;
    }
`;

export const Title = styled.span`
    ${SCREENS.tabletAndLower} {
        font-size: 16px;
        line-height: 19px;
    }

    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${COLORS.blueDarker};
`;

export const Subtitle = styled.span`
    ${SCREENS.tabletAndLower} {
        font-size: 12px;
        line-height: 15px;
    }

    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${COLORS.grey};
`;

export const Button = styled.span`
    ${SCREENS.tabletAndLower} {
        padding: 9px 4px;
        font-size: 9px;
    }

    background: ${COLORS.pink};
    box-shadow: 0 2px 8px ${COLORS.opacify(COLORS.pink, 0.32)};
    border-radius: 50px;
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    padding: 16px 24px;
    margin-top: 20px;
`;
