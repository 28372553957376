import { RegistrationStatusTypes } from '@santeacademie/contentstudio-api-client';

import {
    ERegistrationProgressBarStatus,
    ERegistrationProgressStatus,
    ERegistrationTagStatus,
} from '@santeacademie/constants';
import { COLORS } from '@constants/colors';

export const REGISTRATION_STATUS_COLORS = {
    [RegistrationStatusTypes.VALIDATED]: COLORS.pink,
    [RegistrationStatusTypes.ON_GOING]: COLORS.yellow,
    [RegistrationStatusTypes.CANCELLED]: COLORS.red,
    [RegistrationStatusTypes.NOT_COMPLETED]: COLORS.yellow,
    [RegistrationStatusTypes.COMPLETED]: COLORS.turquoise,
    [RegistrationStatusTypes.PROCESSED]: COLORS.turquoise,
};

export const REGISTRATION_PROGRESS_STATUS_TRANSLATION_KEY = {
    [ERegistrationProgressStatus.END_AT_WITH_EPISODE_NB]: 'endAt',
    [ERegistrationProgressStatus.NOT_STARTED]: 'startAt',
    [ERegistrationProgressStatus.RATE_BEFORE]: '',
    [ERegistrationProgressStatus.TO_RESUME]: 'resumeAt',
};

export const REGISTRATION_TAG_COLORS = {
    [ERegistrationTagStatus.DEFAULT]: COLORS.opacify(COLORS.blueDarker, 0.8),
    [ERegistrationTagStatus.NO_DEPENDENCY]: COLORS.yellow,
    [ERegistrationTagStatus.CRITICAL]: COLORS.red,
};

export const REGISTRATION_PROGRESS_STATUS_COLORS = {
    [ERegistrationProgressBarStatus.NOT_STARTED]: COLORS.pink,
    [ERegistrationProgressBarStatus.STARTED]: COLORS.yellow,
    [ERegistrationProgressBarStatus.BLOCKED]: COLORS.red,
    [ERegistrationProgressBarStatus.FINISH]: COLORS.turquoise,
};
