import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';
import styled from 'styled-components';

const containerHeight = 144;

const Container = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.opacify(COLORS.blueDarker, 0.7)};
    ${SCREENS.tabletAndLower} {
        width: 38px;
        height: 76px;
        top: 26px;
    }
    width: 72px;
    height: ${containerHeight}px;
    top: 48px;
    z-index: 999;
    cursor: pointer;
    transition: 0.05s transform cubic-bezier(0, 0, 1, 1), 0.05s -webkit-transform cubic-bezier(0, 0, 1, 1);
`;

export const LeftChevron = styled(Container)`
    left: 0;
    border-radius: 0 144px 144px 0;
`;

export const RightChevron = styled(Container)`
    right: 0;
    border-radius: 144px 0 0 144px;
`;
