import { useEffect, useState } from 'react';

interface IWindowSize {
    width: number;
    height: number;
}

interface IUseWindow {
    windowSize: IWindowSize;
    setWindowSize({ width, height }: IWindowSize): void;
}

const useWindow = (): IUseWindow => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        windowSize,
        setWindowSize,
    };
};

export default useWindow;
