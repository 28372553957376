import styled from 'styled-components';
import MaterialLinearProgress from '@mui/material/LinearProgress';

import { COLORS } from '@constants/colors';

interface IPercentageInterface {
    readonly percentage?: number;
}

interface ILinearProgress {
    backgroundcolor?: string;
}

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

export const LinearProgress = styled(MaterialLinearProgress)<ILinearProgress>`
    width: 100%;
    height: 8px !important;
    border-radius: 30px;
    .MuiLinearProgress-barColorPrimary {
        background-color: ${({ backgroundcolor }) => backgroundcolor};
    }
`;

export const Value = styled.span<IPercentageInterface>`
    display: inline-block;
    color: ${(props) => (props.percentage && props.percentage >= 100 ? COLORS.turquoise : COLORS.yellow)};
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 100%;
    margin-left: 8px;
`;
