import React from 'react';
import { IRegistrationShort } from '@santeacademie/contentstudio-api-client';

import { useOverflow } from '@hooks/useOverflow';
import { scrollHorizontal } from '@utils/scroll';

import { CardsListOverflowButton, CourseCard } from '../index';
import * as S from './styled';

type ICardsListProps = {
    registrations: IRegistrationShort[];
};

export const CardsList = ({ registrations }: ICardsListProps): JSX.Element => {
    const horizontalRef = React.useRef<HTMLDivElement>(null);
    const { refXOverflowing, refXScrollEnd, refXScrollBegin } = useOverflow(horizontalRef);
    const onClick = (clickType: 'left' | 'right') => {
        // eslint-disable-next-line no-unused-expressions
        horizontalRef && horizontalRef.current && scrollHorizontal(clickType, horizontalRef.current, 800, 400); // 800px is two cards width
    };

    return (
        <S.CoursesListContainer>
            {refXOverflowing && !refXScrollBegin && (
                <CardsListOverflowButton position="left" onClick={() => onClick('right')} />
            )}
            <S.CoursesContainer ref={horizontalRef}>
                {registrations.map((registration) => (
                    <CourseCard key={registration.id} registration={registration} />
                ))}
            </S.CoursesContainer>
            {refXOverflowing && !refXScrollEnd && (
                <CardsListOverflowButton position="right" onClick={() => onClick('left')} />
            )}
        </S.CoursesListContainer>
    );
};
