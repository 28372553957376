import React from 'react';

import { LinearProgressProps } from '@mui/material';

import * as S from './styled';

interface IProgressBarProps extends LinearProgressProps {
    backgroundcolor?: string;
}

export const ProgressBar = ({ backgroundcolor, ...rest }: IProgressBarProps): JSX.Element => (
    <S.Container>
        <S.LinearProgress variant="determinate" backgroundcolor={backgroundcolor} {...rest} />
    </S.Container>
);
