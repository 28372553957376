import { QueryKey } from '@tanstack/react-query';

export const courseMessagesKeys = {
    all: ['courseMessagesKeys'] as const,
    lists: () => [...courseMessagesKeys.all, 'list'] as const,
    list: (courseId: number) => [...courseMessagesKeys.lists(), { courseId }] as const /* list of messages */,
    details: () => [...courseMessagesKeys.all, 'detail'] as const,
    detail: (id: number) => [...courseMessagesKeys.details(), id] as const /* specific message */,
    children: (messageParentId: number) =>
        [...courseMessagesKeys.details(), 'children', messageParentId] as const /* specific message */,
    filters: (courseId: number, tags?: QueryKey) => [...courseMessagesKeys.list(courseId), ...(tags || [])] as const,
};

export const learnerDetailsKeys = {
    all: ['learnerDetailsKeys'] as const,
    lists: () => [...learnerDetailsKeys.all, 'list'] as const,
    list: (filters: string) => [...learnerDetailsKeys.lists(), { filters }] as const,
    details: () => [...learnerDetailsKeys.all, 'detail'] as const,
    detail: (id: string) => [...learnerDetailsKeys.details(), id] as const,
};

export const isLearnerTrainerKeys = {
    all: ['isLearnerTrainerKeys'] as const,
    lists: () => [...isLearnerTrainerKeys.all, 'list'] as const,
    list: (filters: string) => [...isLearnerTrainerKeys.lists(), { filters }] as const,
    details: () => [...isLearnerTrainerKeys.all, 'detail'] as const,
    detail: (id: string) => [...isLearnerTrainerKeys.details(), id] as const,
};

export const registrationResourcingsKeys = {
    all: ['registrationResourcings'] as const,
    lists: () => [...registrationResourcingsKeys.all, 'list'] as const,
    list: (registrationId: number, resourcingLength: number) => [...registrationResourcingsKeys.lists(), registrationId, resourcingLength] as const,
};

export const courseResourcesKeys = {
    all: ['courseResources'] as const,
    lists: () => [...courseResourcesKeys.all, 'list'] as const,
    list: (courseId: number) => [...courseResourcesKeys.lists(), courseId] as const,
};