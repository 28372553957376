import { useContext } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { hoursToMilliseconds } from 'date-fns';

import { ContentStudioApiContext, ILearner, ICourseTrainer } from '@santeacademie/contentstudio-api-client';

import { isLearnerTrainerKeys, learnerDetailsKeys } from 'constants/reactQueryKeysFactory';

interface IUseIsLearnerTrainer {
    courseTrainers: ICourseTrainer[];
}

export const useLearnerDetails = (): UseQueryResult<ILearner, unknown> => {
    const { getLearner } = useContext(ContentStudioApiContext);

    return useQuery(
        learnerDetailsKeys.detail(''),
        async () => {
            return getLearner({});
        },
        {
            staleTime: hoursToMilliseconds(24),
        },
    );
};

export const useIsLearnerTrainer = ({ courseTrainers }: IUseIsLearnerTrainer): UseQueryResult<boolean, unknown> => {
    const { data: learnerDetails } = useLearnerDetails();

    return useQuery(
        isLearnerTrainerKeys.detail(''),
        () => {
            if (learnerDetails === undefined) {
                return false;
            }
            return (
                courseTrainers.find(
                    (courseTrainer) => courseTrainer.trainer.emailHashSha512 === learnerDetails.emailHashSha512,
                ) !== undefined
            );
        },
        {
            staleTime: hoursToMilliseconds(24),
            enabled: !!learnerDetails,
        },
    );
};
