import styled from 'styled-components';

export const UserMenuContainer = styled.div`
    margin-left: 16px;
`;

export const AvatarButton = styled.a`
    background: #f2f2f2;
    border-radius: 50%;
    display: block;
    padding: 2px;
    border: 1px solid #eee;
    cursor: pointer;
`;
