import styled from 'styled-components';

interface ITitle {
    color?: string;
}

interface IPercentageValue {
    isBold?: boolean;
}

export const ResumeAction = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-top: 10px;
    cursor: pointer;
    z-index: 999;
    gap: 8px;
`;

export const Title = styled.span<ITitle>`
    color: ${({ color }) => color};
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-right: 8px;
    display: flex;
    align-items: center;
`;

export const InheritSpan = styled.span`
    color: inherit;
`;

export const PercentageValue = styled.span<IPercentageValue>`
    color: inherit;
    font-size: 13px;
    font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
    display: flex;
    align-items: center;
`;
