import crypto from 'crypto';

export const toAnimalAvatar = (seed: string): string => {
    const maxChoices = 46;
    const shasum = crypto.createHash('sha1');
    shasum.update(seed);
    const hash = shasum.digest('hex');
    const hashPure = hash.replace(/([abcdef]+)/g, '');
    const hashIntCut = parseInt(hashPure.substring(0, 6), 10);
    const index = (hashIntCut % maxChoices) + 1;

    return `/animals/${index}.png`;
};
