import { RegistrationStatusTypes } from '@santeacademie/contentstudio-api-client';

import {
    ERegistrationProgressBarStatus,
    ERegistrationProgressStatus,
    IRegistrationProgress,
} from '@santeacademie/constants';

const getStatusBarStatusFromRegistrationStatus = (
    registrationStatus: RegistrationStatusTypes,
): ERegistrationProgressBarStatus => {
    if (registrationStatus === RegistrationStatusTypes.ON_GOING) {
        return ERegistrationProgressBarStatus.STARTED;
    }
    if (
        registrationStatus === RegistrationStatusTypes.CANCELLED ||
        registrationStatus === RegistrationStatusTypes.NOT_COMPLETED
    ) {
        return ERegistrationProgressBarStatus.BLOCKED;
    }
    if (registrationStatus === RegistrationStatusTypes.COMPLETED) {
        return ERegistrationProgressBarStatus.FINISH;
    }
    return ERegistrationProgressBarStatus.NOT_STARTED;
};

const getShouldShowProgress = (
    registrationStatus: RegistrationStatusTypes,
    registrationProgress: IRegistrationProgress | null,
): boolean =>
    registrationStatus === RegistrationStatusTypes.ON_GOING &&
    registrationProgress?.status !== ERegistrationProgressStatus.TO_RESUME;

export { getStatusBarStatusFromRegistrationStatus, getShouldShowProgress };
