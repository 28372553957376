import React from 'react';

import * as S from './styled';

/**
 * @deprecated
 */
const ButtonLoader = (): JSX.Element => (
    <S.ButtonLoaderContainer>
        <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
    </S.ButtonLoaderContainer>
);

export default ButtonLoader;
