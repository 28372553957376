/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
import { useState, useEffect, RefObject } from 'react';

import useWindow from './useWindow';

const useOverflow = (
    ref: RefObject<HTMLElement>,
): {
    refXOverflowing: boolean;
    refYOverflowing: boolean;
    refXScrollBegin: boolean;
    refXScrollEnd: boolean;
    refYScrollBegin: boolean;
    refYScrollEnd: boolean;
} => {
    const [refXOverflowing, setRefXOverflowing] = useState(false);
    const [refYOverflowing, setRefYOverflowing] = useState(false);

    const [refXScrollBegin, setRefXScrollBegin] = useState(true);
    const [refXScrollEnd, setRefXScrollEnd] = useState(false);

    const [refYScrollBegin, setRefYScrollBegin] = useState(true);
    const [refYScrollEnd, setRefYScrollEnd] = useState(false);

    const { windowSize } = useWindow();

    useEffect(() => {
        if (!ref?.current) {
            return;
        }
        const isXOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
        const isYOverflowing = ref.current.scrollHeight > ref.current.clientHeight;

        if (refXOverflowing !== isXOverflowing) {
            setRefXOverflowing(isXOverflowing);
        }

        if (refYOverflowing !== isYOverflowing) {
            setRefYOverflowing(isYOverflowing);
        }

        const handleScroll = (): void => {
            // Handle X Overflow
            const offsetRight = ref?.current?.scrollWidth! - ref?.current?.clientWidth!;
            if (ref?.current?.scrollLeft! >= offsetRight && refXScrollEnd === false) {
                setRefXScrollEnd(true);
            } else {
                setRefXScrollEnd(false);
            }

            if (ref?.current?.scrollLeft === 0) {
                setRefXScrollBegin(true);
            } else {
                setRefXScrollBegin(false);
            }

            // Handle Y Overflow
            const offsetBottom = ref?.current?.scrollHeight! - ref?.current?.clientHeight!;
            if (Math.ceil(ref?.current?.scrollTop!) >= offsetBottom && refYScrollEnd === false) {
                setRefYScrollEnd(true);
            } else {
                setRefYScrollEnd(false);
            }

            if (ref?.current?.scrollTop === 0) {
                setRefYScrollBegin(true);
            } else {
                setRefYScrollBegin(false);
            }
        };

        ref.current.addEventListener('scroll', handleScroll);

        return (): void => ref.current?.removeEventListener('scroll', handleScroll);
    }, [ref, refXOverflowing, refXScrollEnd, refYOverflowing, refYScrollEnd, windowSize.width]);

    return {
        refXOverflowing,
        refYOverflowing,
        refXScrollBegin,
        refXScrollEnd,
        refYScrollBegin,
        refYScrollEnd,
    };
};

const useDetectScrollEnd = (
    ref: RefObject<HTMLElement>,
): {
    refYScrollEnd: boolean;
} => {
    const [refYScrollEnd, setRefYScrollEnd] = useState(false);

    const { windowSize } = useWindow();

    useEffect(() => {
        if (!ref?.current) {
            return;
        }

        const handleScroll = (): void => {
            // Handle Y Overflow
            const offsetBottom = ref?.current?.scrollHeight! - ref?.current?.clientHeight!;
            if (Math.ceil(ref?.current?.scrollTop!) >= offsetBottom && refYScrollEnd === false) {
                setRefYScrollEnd(true);
            } else {
                setRefYScrollEnd(false);
            }
        };

        ref.current.addEventListener('scroll', handleScroll);

        return (): void => ref.current?.removeEventListener('scroll', handleScroll);
    }, [ref, refYScrollEnd, windowSize.width]);

    return {
        refYScrollEnd,
    };
};

export { useOverflow, useDetectScrollEnd };
