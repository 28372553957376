import React from 'react';

import { ChevronLeft, ChevronRight } from '@assets/svg/Chevron';
import { COLORS } from '@constants/colors';
import useWindow from '@hooks/useWindow';
import { SIZES } from '@constants/sizes';

import * as S from './styled';

type IChevronProps = {
    position: 'left' | 'right';
    onClick: () => void;
};

const tabletOrLowerScale = 1.4;
const desktopScale = 2;

export const CardsListOverflowButton = ({ position, onClick }: IChevronProps): JSX.Element => {
    const { windowSize } = useWindow();

    if (position === 'left') {
        return (
            <S.LeftChevron onClick={onClick}>
                <ChevronLeft
                    fill={COLORS.white}
                    scale={windowSize.width < SIZES.tablet ? tabletOrLowerScale : desktopScale}
                />
            </S.LeftChevron>
        );
    }
    return (
        <S.RightChevron onClick={onClick}>
            <ChevronRight
                fill={COLORS.white}
                scale={windowSize.width < SIZES.tablet ? tabletOrLowerScale : desktopScale}
            />
        </S.RightChevron>
    );
};
