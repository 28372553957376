import { format, formatDistanceToNow, formatDistanceToNowStrict, addDays, addWeeks, addMonths } from 'date-fns';
import { fr } from 'date-fns/locale';

import { UnitPeriodOffserTypes } from '@santeacademie/contentstudio-api-client';

import { t } from '@locales/translation';

export const formatDate = (date: Date, dateFormat: string): string | undefined => {
    if (date) {
        const newDate = new Date(date);
        return format(newDate, dateFormat, { locale: fr });
    }
    return undefined;
};

export const fromNow = (date: Date): string => formatDistanceToNow(date, { locale: fr });
export const fromNowStrict = (date: Date): string =>
    formatDistanceToNowStrict(date, { locale: fr, unit: 'day', roundingMethod: 'floor' });

export const addToDate = (date: Date, unitPeriodOffet: UnitPeriodOffserTypes, valuePeriodOffset: number): Date => {
    if (unitPeriodOffet === UnitPeriodOffserTypes.DAYS) {
        return addDays(date, valuePeriodOffset);
    }
    if (unitPeriodOffet === UnitPeriodOffserTypes.WEEKS) {
        return addWeeks(date, valuePeriodOffset);
    }
    if (unitPeriodOffet === UnitPeriodOffserTypes.MONTHS) {
        return addMonths(date, valuePeriodOffset);
    }
    return date;
};

export const converUnitPeriodOffset = (offset: UnitPeriodOffserTypes): string => t(`time.${offset}`);
