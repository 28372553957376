import React, { useMemo } from 'react';

import Link from 'next/link';
import { format, isBefore } from 'date-fns';

import { IRegistrationShort } from '@santeacademie/contentstudio-api-client';

import { COLORS } from '@constants/colors';
import { t } from '@locales/translation';
import { Badge } from '@components/Badge';
import { getRegistrationProgressionDetails } from '@utils/entities/registration';

import { StatusBar, getStatusBarStatusFromRegistrationStatus } from '../index';
import { CourseCodeBadge } from '../CourseCodeBadge/CourseCodeBadge';
import * as S from './styled';

interface ICourseCardWrapper {
    registrationId: number;
    sessionAccessBeginDate: Date | null;
    children: React.ReactNode;
}

export type ICourseCardProps = {
    registration: IRegistrationShort;
};

const CourseCardWrapper = ({ sessionAccessBeginDate, registrationId, children }: ICourseCardWrapper) => {
    if (sessionAccessBeginDate && isBefore(new Date(), sessionAccessBeginDate)) {
        return (
            <div
                onClick={() => {
                    alert(t('page.home.ongoingCourse.notStartedAlert', { date: format(sessionAccessBeginDate, 'dd/MM') }));
                }}
            >
                {children}
            </div>
        );
    }
    return <Link href={`/registration/${registrationId}`}>{children}</Link>;
};

export const CourseCard = ({ registration }: ICourseCardProps): JSX.Element => {
    const backgroundUrl =
        registration.course.thumbnail?.landscape != null
            ? `${process.env.NEXT_PUBLIC_EXTERNAL_URL}/${registration.course.thumbnail.landscape}`
            : '';
    const registrationRedundantProgression = useMemo(
        () => Math.floor(registration.redundantProgression * 100),
        [registration.redundantProgression]
    );
    const progressionDetails = registration?.progressionMessage != null ? getRegistrationProgressionDetails(registration.progressionMessage) : null;

    return (
        <CourseCardWrapper sessionAccessBeginDate={registration.sessionAccessBeginDate} registrationId={registration.id}>
            <S.Container
                style={
                    backgroundUrl !== ''
                        ? {
                            backgroundImage: `url(${backgroundUrl})`,
                        }
                        : { backgroundColor: COLORS.opacify(COLORS.grey, 0.2) }
                }
            >
                <S.BadgeContainer>
                    {progressionDetails?.title != null && progressionDetails?.color != null ? (
                        <Badge
                            title={progressionDetails?.title}
                            size="small"
                            color="pink"
                            type="dark"
                            state="default"
                            backgroundColor={progressionDetails.color}
                        />
                    ) : null}
                    <CourseCodeBadge course={registration.course} />
                </S.BadgeContainer>
                <S.Footer>
                    <S.Title titleLength={registration.course.name.length}>{registration.course.name}</S.Title>
                    {registration.progressionMessage?.type === 'run' && (
                        <StatusBar
                            status={getStatusBarStatusFromRegistrationStatus(registration.status)}
                            registrationId={registration.id}
                            progression={registrationRedundantProgression}
                        />
                    )}
                </S.Footer>
            </S.Container>
        </CourseCardWrapper>
    );
};
