import { isAfter, startOfDay, format } from 'date-fns';

import { IProgressionMessage, IRegistrationShort, RegistrationStatusTypes } from '@santeacademie/contentstudio-api-client';


import { COLORS } from '@constants/colors';
import { t } from '@locales/translation';

export const filterCurrentRegistrationsShort = (registrationsShort: IRegistrationShort[]): IRegistrationShort[] =>
    registrationsShort.filter((learnerRegistration) => learnerRegistration.status === RegistrationStatusTypes.ON_GOING);

export const filterPastRegistrationsShort = (registrationsShort: IRegistrationShort[]): IRegistrationShort[] =>
    registrationsShort.filter(
        (learnerRegistration) =>
            [
                RegistrationStatusTypes.COMPLETED,
                RegistrationStatusTypes.NOT_COMPLETED,
                RegistrationStatusTypes.PROCESSED,
            ].indexOf(learnerRegistration.status) !== -1,
    );

export const sortRegistrationsShortByStartedAd = (registrationsShort: IRegistrationShort[]): IRegistrationShort[] =>
    registrationsShort.sort((registration1, registration2) => {
        if (!registration1.startedAt || !registration2.startedAt) return 0;
        if (registration1.startedAt && registration2.startedAt) {
            return registration1.startedAt.getTime() - registration2.startedAt.getTime();
        }
        return 0;
    });


export const getIsScheduledDateAfterToday = (scheduledForumDeactivation: Date): boolean => {
    // get the actual day with time 00:00
    const today = startOfDay(new Date());
    return isAfter(scheduledForumDeactivation, today);
}
export const getRegistrationProgressionDetails = (progressionMessage: IProgressionMessage): { title: string | null, color: string | null } => {
    const { type, dateTime, countEpisodes } = progressionMessage;

    const titleMap = {
        waiting: dateTime != null ? t('page.home.ongoingCourse.courseDate.waiting', { date: format(dateTime, 'dd/MM') }) : null,
        begin: dateTime != null ? t('page.home.ongoingCourse.courseDate.begin', { date: format(dateTime, 'dd/MM') }) : null,
        run: dateTime != null && countEpisodes != null ? t('page.home.ongoingCourse.courseDate.run', { date: format(dateTime, 'dd/MM'), count: countEpisodes }) : null,
    };

    const colorMap = {
        waiting: COLORS.blueDarker,
        begin: COLORS.blueDarker,
        run: COLORS.red,
    };

    return { title: titleMap[type], color: colorMap[type] };
};
