import React from 'react';

import { format, isBefore } from 'date-fns';

import { IRegistrationShort } from '@santeacademie/contentstudio-api-client';

import { Play } from '@assets/svg/Play';
import { t } from '@locales/translation';
import useWindow from '@hooks/useWindow';
import { COLORS } from '@constants/colors';
import { SIZES } from '@constants/sizes';
import PrimaryButton from '@components/Button/PrimaryButton';
import { DetailedCourseDatas } from '@components/Cards';
import { Badge } from '@components/Badge';
import { getRegistrationProgressionDetails } from '@utils/entities/registration';

import { CourseCodeBadge } from '../CourseCodeBadge/CourseCodeBadge';
import * as S from './styled';

type IDetailedCourseCardProps = {
    registration?: IRegistrationShort;
};

export const DetailedCourseCard = ({ registration }: IDetailedCourseCardProps): JSX.Element => {
    const { windowSize } = useWindow();

    const onClickRegistrationLink = () => {
        const sessionAccessBeginDate = registration?.sessionAccessBeginDate;
        if (sessionAccessBeginDate != null && isBefore(new Date(), sessionAccessBeginDate)) {
            alert(t('page.home.ongoingCourse.notStartedAlert', { date: format(sessionAccessBeginDate, 'dd/MM') }));
            return;
        }
        const link = registration ? `/registration/${registration.id}` : 'https://santeacademie.com/';
        document.location.href = link;
    };

    const progressionDetails = registration?.progressionMessage != null ? getRegistrationProgressionDetails(registration.progressionMessage) : null;

    return (
        <S.Container
            onClick={onClickRegistrationLink}
            style={
                registration?.course?.thumbnail?.landscape != null
                    ? {
                        backgroundImage: `url(${process.env.NEXT_PUBLIC_EXTERNAL_URL}/${registration.course.thumbnail.landscape})`,
                    }
                    : {
                        backgroundColor: COLORS.opacify(COLORS.grey, 0.2),
                    }
            }
        >
            {progressionDetails?.title != null && progressionDetails?.color != null ? (
                <S.TagContainer>
                    <Badge
                        title={progressionDetails.title}
                        size="medium"
                        color="pink"
                        type="dark"
                        state="default"
                        backgroundColor={progressionDetails.color}
                    />
                    {registration != null ? <CourseCodeBadge course={registration.course} /> : null}
                </S.TagContainer>
            ) : null}
            {/* Course informations */}
            {registration ? (
                <>
                    <S.ResumeButtonContainer>
                        <PrimaryButton>
                            <Play fill={COLORS.white} scale={windowSize.width <= SIZES.tablet ? 0.8 : 1} />
                            <S.ButtonLabel>{t('page.home.ongoingCourse.resumeButtonLabel')}</S.ButtonLabel>
                        </PrimaryButton>
                    </S.ResumeButtonContainer>
                    <DetailedCourseDatas registration={registration} />
                </>
            ) : (
                <S.ResumeButtonContainer>
                    <a target="_blank" href="https://santeacademie.com/" rel="noopener noreferrer">
                        <PrimaryButton>
                            <S.ButtonLabel>
                                {windowSize.width <= SIZES.tablet
                                    ? t('page.home.ongoingCourse.emptyShort')
                                    : t('page.home.ongoingCourse.empty')}
                            </S.ButtonLabel>
                        </PrimaryButton>
                    </a>
                </S.ResumeButtonContainer>
            )}
        </S.Container>
    );
};
