import { SCREENS } from '@constants/screens';
import styled from 'styled-components';

export const CoursesListContainer = styled.div`
    position: relative;
`;

export const CoursesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    ${SCREENS.tabletAndLower} {
        padding: 0 20px 0 20px;
    }
    padding: 0 88px 0 88px;
    &::after {
        content: '';
        border-right: 20px solid transparent;
    }
`;
