import Link from 'next/link';

import { ERegistrationProgressBarStatus } from '@santeacademie/constants';

import { ProgressBar } from '@components/Progress/LinearProgress/ProgressBar';
import { Play } from '@assets/svg/Play';
import { t } from '@locales/translation';
import { REGISTRATION_PROGRESS_STATUS_COLORS } from '@constants/registration/status';

import * as S from './styled';

interface IStatusBar {
    status: ERegistrationProgressBarStatus;
    registrationId: number;
    progression: number;
}

interface IStatusBarTitle {
    status: ERegistrationProgressBarStatus;
    progression: number;
}

const getStatusBarTitle = (
    status: ERegistrationProgressBarStatus,
    registrationRedundantProgression: number,
): JSX.Element => {
    switch (status) {
        case ERegistrationProgressBarStatus.NOT_STARTED:
            return <S.InheritSpan>{t('page.home.ongoingCourse.progressBar.notStarted')}</S.InheritSpan>;
        case ERegistrationProgressBarStatus.STARTED:
            return (
                <>
                    {t('page.home.ongoingCourse.progressBar.started')}&nbsp;
                    <S.PercentageValue isBold>{registrationRedundantProgression}%</S.PercentageValue>
                </>
            );
        case ERegistrationProgressBarStatus.BLOCKED:
            return (
                <>
                    {t('page.home.ongoingCourse.progressBar.blocked')}&nbsp;
                    <S.PercentageValue>{registrationRedundantProgression}% 😢</S.PercentageValue>
                </>
            );
        case ERegistrationProgressBarStatus.FINISH:
            return <S.InheritSpan>{t('page.home.ongoingCourse.progressBar.finish')} ! 🎉</S.InheritSpan>;
        default:
            return <S.InheritSpan></S.InheritSpan>;
    }
};

const StatusBarTitle = ({ status, progression }: IStatusBarTitle) => {
    return (
        <S.Title color={REGISTRATION_PROGRESS_STATUS_COLORS[status]}>{getStatusBarTitle(status, progression)}</S.Title>
    );
};

export const StatusBar = ({ status, registrationId, progression }: IStatusBar): JSX.Element => {
    const displayPlayButton =
        status === ERegistrationProgressBarStatus.NOT_STARTED || status === ERegistrationProgressBarStatus.STARTED;
    return (
        <div>
            <Link href={`/registration/${registrationId}`}>
                <S.ResumeAction>
                    {displayPlayButton ? <Play fill={REGISTRATION_PROGRESS_STATUS_COLORS[status]} /> : null}
                    <StatusBarTitle status={status} progression={progression} />
                </S.ResumeAction>
            </Link>
            <ProgressBar
                value={progression}
                style={{ marginTop: 8 }}
                backgroundcolor={REGISTRATION_PROGRESS_STATUS_COLORS[status]}
            />
        </div>
    );
};
