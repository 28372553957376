import React from 'react';

import ButtonLoader from './Loader/ButtonLoader';
import * as S from './styled';

export type IPrimaryButtonProps = {
    loading?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    children: React.ReactNode;
};

/**
 * @deprecated
 */
const PrimaryButton: React.FC<IPrimaryButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
    loading,
    disabled,
    style,
    children,
    ...props
}: IPrimaryButtonProps): JSX.Element => (
    <S.PrimaryButton style={style} disabled={disabled} {...props}>
        {loading && <ButtonLoader />}
        <S.ChildrenContainer $loading={loading}>{children}</S.ChildrenContainer>
    </S.PrimaryButton>
);

export default PrimaryButton;
