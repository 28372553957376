import { ICourseShort } from "@santeacademie/contentstudio-api-client";
import { Badge } from "@santeacademie/synapse-composition-react";

import { useLearnerDetails } from "@hooks/api/useLearner"
import { isEmployeeEmail } from "@utils/mail"

interface ICourseCodeBadge {
  course: ICourseShort;
}

const CourseCodeBadge = ({ course }: ICourseCodeBadge): React.ReactElement | null => {
  const { data: learnerDetails } = useLearnerDetails()
  const isCurrentUserEmployee = learnerDetails != null ? isEmployeeEmail(learnerDetails.email) : false;

  if (isCurrentUserEmployee) {
    return <Badge
      label={String(course.code)}
      color="slate"
      variant="lowEmphasis"
    />
  }

  return null
}

export { CourseCodeBadge }