import React from 'react';

import Svg, {ISvgIcon} from './Svg';

const Play = ({scale, fill}: ISvgIcon): JSX.Element => (
	<Svg width={16} height={16} viewBox='0 0 16 16' scale={scale} fill={fill}>
		<path d='M13.5 7.1263C14.1667 7.51461 14.1667 8.48539 13.5 8.8737L1.5 15.8633C0.833334 16.2516 -3.3649e-08 15.7663 0 14.9896L6.05683e-07 1.01037C6.39332e-07 0.233746 0.833334 -0.251645 1.5 0.136667L13.5 7.1263Z' />
	</Svg>
);

export {Play};
